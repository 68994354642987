import { VoipState } from './VoipProvider'

export enum VoipActionType {
  CLEAR_ERROR = 'clear_error',
  CLEAR_INBOUND_CALL = 'clear_inbound_call',
  CLEAR_OUTBOUND_CALL = 'clear_outbound_call',
  CLEAR_USER_LEAD = 'clear_user_lead',
  END_CALL = 'end_call',
  SET_AVAILABLE_INPUT_DEVICES = 'set_available_input_devices',
  SET_AVAILABLE_OUTPUT_DEVICES = 'set_available_output_devices',
  SET_DEV_MODE = 'set_dev_mode',
  SET_DEVICE = 'set_device',
  SET_ERROR = 'set_error',
  SET_INBOUND_CALL = 'set_inbound_call',
  SET_INBOUND_CALL_ACCEPTED = 'set_inbound_call_accepted',
  SET_MICROPHONE_PERMISSION_GRANTED = 'set_microphone_permission_granted',
  SET_OUTBOUND_CALL = 'set_outbound_call',
  SET_SELECTED_INPUT_DEVICE_ID = 'set_selected_input_device_id',
  SET_SELECTED_OUTPUT_DEVICE_ID = 'set_selected_output_device_id',
  SET_SHOULD_REFRESH_TOKEN = 'set_should_refresh_token',
  SET_USER_LEAD = 'set_user_lead',
  SET_VOIP_ALLOWED = 'set_voip_allowed',
  SET_VOIP_IS_CONNECTED = 'set_voip_is_connected',
  SET_VOIP_SHOULD_BE_CONNECTED = 'set_voip_should_be_connected',
}

export type VoipAction =
  | { type: VoipActionType.CLEAR_ERROR }
  | { type: VoipActionType.CLEAR_INBOUND_CALL }
  | { type: VoipActionType.CLEAR_OUTBOUND_CALL }
  | { type: VoipActionType.CLEAR_USER_LEAD }
  | { type: VoipActionType.END_CALL }
  | {
      type: VoipActionType.SET_AVAILABLE_INPUT_DEVICES
      payload: { availableInputDevices: VoipState['availableInputDevices'] }
    }
  | {
      type: VoipActionType.SET_AVAILABLE_OUTPUT_DEVICES
      payload: { availableOutputDevices: VoipState['availableOutputDevices'] }
    }
  | { type: VoipActionType.SET_DEV_MODE; payload: { devMode: VoipState['devMode'] } }
  | { type: VoipActionType.SET_DEVICE; payload: { device: VoipState['device'] } }
  | { type: VoipActionType.SET_ERROR; payload: { error: string } }
  | {
      type: VoipActionType.SET_INBOUND_CALL
      payload: {
        inboundCall: VoipState['inboundCall']
      }
    }
  | {
      type: VoipActionType.SET_INBOUND_CALL_ACCEPTED
      payload: { inboundCallAccepted: boolean }
    }
  | {
      type: VoipActionType.SET_MICROPHONE_PERMISSION_GRANTED
      payload: { microphonePermissionGranted: VoipState['microphonePermissionGranted'] }
    }
  | {
      type: VoipActionType.SET_OUTBOUND_CALL
      payload: { outboundCall: VoipState['outboundCall'] }
    }
  | {
      type: VoipActionType.SET_SELECTED_INPUT_DEVICE_ID
      payload: { selectedInputDeviceId: VoipState['selectedInputDeviceId'] }
    }
  | {
      type: VoipActionType.SET_SELECTED_OUTPUT_DEVICE_ID
      payload: { selectedOutputDeviceId: VoipState['selectedOutputDeviceId'] }
    }
  | {
      type: VoipActionType.SET_SHOULD_REFRESH_TOKEN
      payload: { shouldRefreshToken: VoipState['shouldRefreshToken'] }
    }
  | { type: VoipActionType.SET_USER_LEAD; payload: { userLead: VoipState['userLead'] } }
  | { type: VoipActionType.SET_VOIP_ALLOWED; payload: { voipAllowed: VoipState['voipAllowed'] } }
  | {
      type: VoipActionType.SET_VOIP_IS_CONNECTED
      payload: { voipIsConnected: VoipState['voipIsConnected'] }
    }
  | {
      type: VoipActionType.SET_VOIP_SHOULD_BE_CONNECTED
      payload: { voipShouldBeConnected: VoipState['voipShouldBeConnected'] }
    }

export const voipReducer = (state: VoipState, action: VoipAction): VoipState => {
  switch (action.type) {
    case VoipActionType.CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      }
    case VoipActionType.CLEAR_INBOUND_CALL:
      return {
        ...state,
        inboundCall: undefined,
        inboundCallAccepted: false,
      }
    case VoipActionType.CLEAR_OUTBOUND_CALL:
      return {
        ...state,
        outboundCall: undefined,
        userLead: undefined,
      }
    case VoipActionType.CLEAR_USER_LEAD:
      return {
        ...state,
        userLead: undefined,
      }
    case VoipActionType.END_CALL:
      return {
        ...state,
        inboundCall: undefined,
        inboundCallAccepted: false,
        outboundCall: undefined,
      }
    case VoipActionType.SET_AVAILABLE_INPUT_DEVICES:
      return {
        ...state,
        availableInputDevices: action.payload.availableInputDevices,
      }
    case VoipActionType.SET_AVAILABLE_OUTPUT_DEVICES:
      return {
        ...state,
        availableOutputDevices: action.payload.availableOutputDevices,
      }
    case VoipActionType.SET_DEV_MODE:
      sessionStorage.setItem('voip-dev-mode', action.payload.devMode ? 'true' : 'false')
      return {
        ...state,
        devMode: action.payload.devMode,
      }
    case VoipActionType.SET_DEVICE:
      return {
        ...state,
        device: action.payload.device,
      }
    case VoipActionType.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      }
    case VoipActionType.SET_INBOUND_CALL:
      return {
        ...state,
        inboundCall: action.payload.inboundCall,
      }
    case VoipActionType.SET_INBOUND_CALL_ACCEPTED:
      return {
        ...state,
        inboundCallAccepted: action.payload.inboundCallAccepted,
      }
    case VoipActionType.SET_MICROPHONE_PERMISSION_GRANTED:
      return {
        ...state,
        microphonePermissionGranted: action.payload.microphonePermissionGranted,
      }
    case VoipActionType.SET_OUTBOUND_CALL:
      return {
        ...state,
        outboundCall: action.payload.outboundCall,
      }
    case VoipActionType.SET_SELECTED_INPUT_DEVICE_ID:
      localStorage.setItem(
        'last-selected-input-device-id',
        action.payload.selectedInputDeviceId || ''
      )
      return {
        ...state,
        selectedInputDeviceId: action.payload.selectedInputDeviceId,
      }
    case VoipActionType.SET_SELECTED_OUTPUT_DEVICE_ID:
      localStorage.setItem(
        'last-selected-output-device-id',
        action.payload.selectedOutputDeviceId || ''
      )
      return {
        ...state,
        selectedOutputDeviceId: action.payload.selectedOutputDeviceId,
      }
    case VoipActionType.SET_SHOULD_REFRESH_TOKEN:
      return {
        ...state,
        shouldRefreshToken: action.payload.shouldRefreshToken,
      }
    case VoipActionType.SET_USER_LEAD:
      return {
        ...state,
        userLead: action.payload.userLead,
      }
    case VoipActionType.SET_VOIP_ALLOWED:
      return {
        ...state,
        voipAllowed: action.payload.voipAllowed,
      }
    case VoipActionType.SET_VOIP_IS_CONNECTED:
      return {
        ...state,
        voipIsConnected: action.payload.voipIsConnected,
      }
    case VoipActionType.SET_VOIP_SHOULD_BE_CONNECTED:
      return {
        ...state,
        voipShouldBeConnected: action.payload.voipShouldBeConnected,
      }
    default:
      return state
  }
}
