import { useEffect } from 'react'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import { useStopwatch } from 'react-timer-hook'
import { Phone, Mic, MicOff } from 'lucide-react'
import cn from 'classnames'
import { Icon, Text } from '@leadrilla/pulsar'
import { LeadFeed } from '../../../types/lead_feed'
import { UserLead } from '../../../types/user_lead'
import { useVoip } from '../VoipProvider'
import { useCampaigns } from '../../../hooks/campaigns'
import CallSpinnerIcon from '../../../components/CallSpinnerIcon/call_spinner_icon'
import { Tooltip, TooltipTrigger, TooltipContent } from '../../../components/ui/tooltip'
import { useEnabledCallCampaign } from 'src/hooks/queries/useEnabledCallCampaign'

const State = TwilioCall.State

const Notch = ({
  showCampaignDetailsWindow,
  toggleShowCampaignDetailsWindow,
  outboundCall,
  userLead,
}: {
  showCampaignDetailsWindow: boolean
  toggleShowCampaignDetailsWindow: () => void
  outboundCall?: TwilioCall
  userLead?: UserLead
}) => {
  const { voipState } = useVoip()
  const { data: enabledCallCampaign } = useEnabledCallCampaign()

  const pending =
    voipState.inboundCall?.status() === State.Pending || outboundCall?.status() === State.Pending
  const open =
    voipState.inboundCall?.status() === State.Open ||
    voipState.inboundCall?.status() === State.Connecting ||
    outboundCall?.status() === State.Open ||
    outboundCall?.status() === State.Connecting
  const closed =
    voipState.inboundCall?.status() === State.Closed || outboundCall?.status() === State.Closed

  return (
    <div
      className={cn('flex h-[82px] items-center rounded-t-[16px] px-8', {
        'bg-[--colors-action]': !open,
        'bg-[--colors-positive]': open,
      })}
    >
      {((enabledCallCampaign && !voipState.inboundCall) || (enabledCallCampaign && pending)) && (
        <CampaignNotch
          campaign={enabledCallCampaign}
          showCampaignDetailsWindow={showCampaignDetailsWindow}
          toggleShowCampaignDetailsWindow={toggleShowCampaignDetailsWindow}
        />
      )}
      {voipState.inboundCall && (open || closed) && (
        <InboundCallNotch inboundCall={voipState.inboundCall} />
      )}
      {outboundCall && userLead && (
        <OutboundCallNotch outboundCall={outboundCall} userLead={userLead} />
      )}
    </div>
  )
}

export default Notch

const CampaignNotch = ({
  campaign,
  showCampaignDetailsWindow,
  toggleShowCampaignDetailsWindow,
}: {
  campaign: LeadFeed
  showCampaignDetailsWindow: boolean
  toggleShowCampaignDetailsWindow: () => void
}) => {
  const { voipState, triggerDevModeInboundCall } = useVoip()
  const { pauseCampaignWithReason } = useCampaigns()

  return (
    <>
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-6">
          <CallSpinnerIcon spinner />
          <div className="flex flex-col items-center space-y-4">
            <Text tone="ghost" size="small" truncate>
              Waiting for a call...
            </Text>
            <Text tone="ghost" size="big" weight="stronger" truncate>
              {campaign.product?.name}
            </Text>
          </div>
        </div>
        <div className="flex items-center gap-10">
          {voipState.devMode && (
            <div className="cursor-pointer">
              <Icon name="phone" tone="ghost" onClick={triggerDevModeInboundCall} />
            </div>
          )}
          <button
            onClick={() => {
              pauseCampaignWithReason(campaign.id)
            }}
            className="rounded-md border-2 border-solid border-white px-[10px] py-[6px]"
          >
            <Text size="small" weight="stronger" tone="ghost">
              Pause Campaign
            </Text>
          </button>
          <div className="cursor-pointer" onClick={() => toggleShowCampaignDetailsWindow()}>
            <Icon name="arrow" rotate={showCampaignDetailsWindow ? 0 : 180} size="l" tone="ghost" />
          </div>
        </div>
      </div>
    </>
  )
}

const InboundCallNotch = ({ inboundCall }: { inboundCall: TwilioCall }) => {
  const { data: enabledCallCampaign } = useEnabledCallCampaign()

  const open = inboundCall.status() === State.Open
  const muted = inboundCall.isMuted()

  return (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-6">
        <CallSpinnerIcon />
        <div className="flex flex-col space-y-4">
          <Text tone="ghost" size="small" truncate>
            {open ? 'Active Call' : 'Call Ended'}
            {enabledCallCampaign && ` (${enabledCallCampaign.product?.name})`}
          </Text>
          <div className="flex gap-4">
            <Text tone="ghost" size="big" weight="stronger" truncate>
              {parsePhoneNumberFromString(inboundCall.parameters.From)?.formatNational()}
            </Text>
            <CallDurationTimer inboundCall={inboundCall} />
          </div>
        </div>
      </div>
      {open && (
        <div className="flex select-none items-center gap-6">
          <MuteButton muted={muted} toggleMute={() => inboundCall.mute(!muted)} />
          <HangupButton hangup={() => inboundCall.disconnect()} />
        </div>
      )}
    </div>
  )
}

const OutboundCallNotch = ({
  outboundCall,
  userLead,
}: {
  outboundCall: TwilioCall
  userLead?: UserLead
}) => {
  const status = outboundCall.status()
  const open = status === State.Open || status === State.Connecting
  const muted = outboundCall.isMuted()

  return (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-6">
        <CallSpinnerIcon />
        <div className="flex flex-col space-y-2">
          <Text tone="ghost" size="small" truncate>
            {open ? 'Active Call ' : 'Call Ended '}
            {parsePhoneNumber(outboundCall.customParameters.get('To') || '')?.formatNational()}
          </Text>
          <div className="flex gap-4">
            <Text tone="ghost" size="bigger" weight="stronger">
              {userLead?.first_name} {userLead?.last_name}
            </Text>
          </div>
        </div>
      </div>
      {open && (
        <>
          <div className="flex gap-12">
            <div className="flex select-none items-center gap-6">
              <MuteButton muted={muted} toggleMute={() => outboundCall.mute(!muted)} />
              <HangupButton hangup={() => outboundCall.disconnect()} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const CallDurationTimer = ({
  inboundCall,
  outboundCall,
}: {
  inboundCall?: TwilioCall
  outboundCall?: TwilioCall
}) => {
  const { hours, minutes, seconds, pause } = useStopwatch({ autoStart: true })

  const open = inboundCall?.status() === State.Open || outboundCall?.status() === State.Open

  useEffect(() => {
    if (!open) pause()
  }, [open])

  return (
    <Text tone="ghost" size="big" weight="stronger" truncate>
      {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}
    </Text>
  )
}

const MuteButton = ({ muted, toggleMute }: { muted: boolean; toggleMute: () => void }) => (
  <Tooltip>
    <TooltipTrigger>
      <button
        className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-full bg-white"
        onClick={toggleMute}
      >
        {muted ? <MicOff color="black" size={18} /> : <Mic color="black" size={18} />}
      </button>
    </TooltipTrigger>
    <TooltipContent>{muted ? 'Unmute' : 'Mute'}</TooltipContent>
  </Tooltip>
)

const HangupButton = ({ hangup }: { hangup: () => void }) => (
  <Tooltip>
    <TooltipTrigger>
      <button
        className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-full bg-white"
        onClick={hangup}
      >
        <Phone color="red" size={18} style={{ transform: 'rotate(135deg)' }} />
      </button>
    </TooltipTrigger>
    <TooltipContent>End call</TooltipContent>
  </Tooltip>
)
