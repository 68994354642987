import { Dispatch } from 'react'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { VoipActionType, VoipAction } from './VoipReducer'

// Fake Twilio outbound call for dev mode
export class DevModeOutboundCall {
  state: TwilioCall.State
  muted: boolean = false
  customParameters: Map<string, string>
  dispatch: Dispatch<VoipAction>

  constructor(_dispatch: Dispatch<VoipAction>) {
    this.state = TwilioCall.State.Open
    // Outbound parameters are in a map called customParameters
    this.customParameters = new Map()
    this.customParameters.set('From', '+18598675309')
    this.customParameters.set('To', '+18596636512') // In prod, this is where we'll grab the userLead's number from
    this.dispatch = _dispatch
  }

  status() {
    return this.state
  }

  accept() {
    this.state = TwilioCall.State.Open
    this.dispatch({
      type: VoipActionType.SET_OUTBOUND_CALL,
      payload: { outboundCall: this as unknown as TwilioCall },
    })
  }

  reject() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SET_OUTBOUND_CALL,
      payload: { outboundCall: this as unknown as TwilioCall },
    })
  }

  disconnect() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SET_OUTBOUND_CALL,
      payload: { outboundCall: this as unknown as TwilioCall },
    })
  }

  mute(shouldMute?: boolean) {
    this.muted = shouldMute ?? true
    this.dispatch({
      type: VoipActionType.SET_OUTBOUND_CALL,
      payload: { outboundCall: this as unknown as TwilioCall },
    })
  }

  isMuted() {
    return this.muted
  }

  setError() {
    this.state = TwilioCall.State.Closed
    this.dispatch({ type: VoipActionType.SET_ERROR, payload: { error: 'There was an error' } })
    this.dispatch({ type: VoipActionType.CLEAR_USER_LEAD })
  }

  sendDigits(key: string) {
    console.log(`sent key ${key}`)
  }
}
