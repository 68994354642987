import { DestructiveButton, PrimaryButton, Text } from '@leadrilla/pulsar'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useEnabledCallCampaign } from 'src/hooks/queries/useEnabledCallCampaign'

const HandleInboundVoipCallWindow = ({ inboundCall }: { inboundCall: TwilioCall }) => {
  const { data: enabledCallCampaign } = useEnabledCallCampaign()

  return (
    <div className="flex h-full flex-col items-center justify-center gap-[24px]">
      <Text size="huge" weight="strong" align="center">
        Incoming call...
      </Text>
      {!enabledCallCampaign && (
        <Text size="huge" weight="stronger" align="center">
          {parsePhoneNumberFromString(inboundCall.parameters.From)?.formatNational()}
        </Text>
      )}
      <div className="flex items-center gap-[24px]">
        <PrimaryButton onClick={() => inboundCall.accept()}>Answer</PrimaryButton>
        <DestructiveButton onClick={() => inboundCall.reject()}>Decline</DestructiveButton>
      </div>
    </div>
  )
}

export default HandleInboundVoipCallWindow
