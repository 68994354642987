import React, { useContext, useEffect, useState } from 'react'
import { Lead } from '../../../types/lead'
import { DateTime } from 'luxon'
import { UserContext } from '../../../components/AuthProvider/auth_provider'
import { useTenant } from '../../../hooks/TenantConfig'
import { Box, Card, Inline, Stack, Text, Dialog, Divider, PrimaryButton } from '@leadrilla/pulsar'
import { formatUSD } from '../../../helpers/currency'
import LeadEmailPreview from '../../../components/LeadEmailPreview/lead_email_preview'
import backend from '../../../backend'
import { OK } from '../../../constants/error_codes'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const LeadTimeline = ({ leadId }: { leadId: string }) => {
  const { user } = useContext(UserContext)
  const tenant = useTenant()
  const [lead, setLead] = useState<Lead>()
  const [timelineItems, setTimelineItems] = useState<any>()
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [leadStatuses, setLeadStatuses] = useState<any>()

  const refreshLeadData = ({ leadId }: { leadId: string }) => {
    backend.get(`/leads/${leadId}`).then(({ status, body }) => {
      if (status === OK) {
        let phone = body.phone
        try {
          phone = parsePhoneNumberFromString(body.phone, 'US')

          // trigger error if phone can't be parsed
          phone.getURI()
        } catch (e) {
          phone = false
        }

        const lead = {
          ...body,
          phone,
        }

        setLead(lead)

        backend.get(`/leads/${lead.product_id || 'all'}/statuses`).then(({ status, body }) => {
          if (status === OK) {
            setLeadStatuses(body)
            makeTimelineItems({ lead, leadStatuses: [body] })
          }
        })
      }
    })
  }

  useEffect(() => {
    refreshLeadData({ leadId })
  }, [])

  const makeTimelineItems = ({ lead, leadStatuses }: { lead: Lead; leadStatuses: any }) => {
    const items = lead.statuses.concat(lead.comments)
    if (lead.first_accessed_at && user?.impersonator?.id) {
      items.push({ created_at: lead.first_accessed_at, accessed: true })
    }

    if (lead.calls?.length) {
      items.push(
        ...lead.calls.map((call: any) => {
          return { created_at: call.created_at, call: call }
        })
      )
    }

    if (lead.lead_credit_issuance_reason) {
      items.push({
        created_at: lead.lead_credit_issuance_date,
        amount: lead.lead_credit_issuance_amount,
        lead_credit_issuance: true,
      })
    }

    items.sort((a: any, b: any) => {
      return DateTime.fromISO(b.created_at).toMillis() - DateTime.fromISO(a.created_at).toMillis()
    })

    if (lead.transaction && lead.transaction.type === 'marketplace_order') {
      setTimelineItems(
        items.concat([
          { transaction: lead.transaction, created_at: items[items.length - 1].created_at },
          {
            content: (
              <p className="m-0">
                {lead.first_name} {lead.last_name} responded to an ad and became a lead on{' '}
                {tenant.name}.
              </p>
            ),
            created_at: lead.created_at,
          },
        ])
      )
    } else if (
      lead.product?.type === 'leads' &&
      (lead.product?.sms_communication || lead.product?.email_communication)
    ) {
      addLeadCommunicationToTimeline({ items, lead })
    } else {
      setTimelineItems(items)
    }
  }

  const addLeadCommunicationToTimeline = ({ items, lead }: { items: any; lead: Lead }) => {
    if (!lead.message) {
      setTimelineItems(items)
      return
    }

    let newItems = []

    if (lead.sent_email) {
      newItems.push({
        email: true,
        created_at: items[items.length - 1].created_at,
        updated_at: items[items.length - 1].updated_at,
      })
    }

    if (lead.sent_sms) {
      newItems.push({
        sms: lead.message,
        created_at: items[items.length - 1].created_at,
        updated_at: items[items.length - 1].updated_at,
      })
    }

    items.splice(-1, 0, ...newItems)
    setTimelineItems(items)
  }

  const renderComment = ({ item }: { item: any }) => {
    return (
      <>
        <Text>Note added</Text>
        <div className="lr-blockquote p-2">{item.comment}</div>
      </>
    )
  }

  const renderLeadCreditIssue = ({ item }: { item: any }) => {
    return <Text>Refund issued: {formatUSD(item.amount)}</Text>
  }

  const renderStatus = ({ item }: { item: any }) => {
    return (
      <Text>
        Status updated to{' '}
        <span className="bold caps">
          {leadStatuses ? leadStatuses[item.status]?.name : item.status}
        </span>
        .
      </Text>
    )
  }

  const renderSms = ({ item }: { item: any }) => {
    return (
      <>
        <Text component="p">Text message sent to {lead ? lead.phone.formatNational() : ''}.</Text>
        <div className="lr-blockquote p-2">{item.sms}</div>
      </>
    )
  }

  const renderEmail = ({ item }: { item: any }) => {
    return (
      <>
        <Text>Email sent to {lead?.email}. </Text>
        <div onClick={() => setShowEmailModal(true)}>
          <Text tone="action" weight="strong">
            View email
          </Text>
        </div>
      </>
    )
  }

  const renderTransaction = () => {
    return <Text>Purchased from the Marketplace.</Text>
  }

  const renderAccessed = () => {
    return (
      <>
        {user && (
          <Text>
            {user.first_name} {user.last_name} first viewed this lead.
          </Text>
        )}
      </>
    )
  }

  const renderCall = ({ item }: { item: any }) => {
    return (
      <Stack space="m">
        <Text>
          {item.call.type === 'outbound'
            ? 'Outbound Call'
            : item.call.type === 'inbound'
            ? 'Inbound Call'
            : lead?.product?.name}
        </Text>

        {item.call.recording_url && (
          <audio controls style={{ outline: 'none' }}>
            <source src={item.call.recording_url} type="audio/mpeg" />
            Your browser does not support audio
          </audio>
        )}
      </Stack>
    )
  }

  return (
    <>
      {timelineItems?.length > 0 && (
        <>
          <div className="py-4">
            <Text size="big" weight="stronger">
              Timeline
            </Text>
          </div>

          <div className="space-y-4">
            {timelineItems.map((item: any, index: number) => {
              const date = DateTime.fromISO(item.created_at).toFormat('MMM d, yyyy')
              const time = DateTime.fromISO(item.created_at).toFormat('h:mma').toLowerCase()

              return (
                <Card key={index} insetStretch="s">
                  <Stack space="s">
                    <Inline alignX="between" alignY="top">
                      <Text weight="stronger">{date}</Text>
                      <Text weight="stronger">{time}</Text>
                    </Inline>

                    {item.content}
                    {item.comment && renderComment({ item })}
                    {item.status && renderStatus({ item })}
                    {item.sms && renderSms({ item })}
                    {item.email && renderEmail(item)}
                    {item.transaction && renderTransaction()}
                    {item.accessed && renderAccessed()}
                    {item.call && renderCall({ item })}
                    {item.lead_credit_issuance && renderLeadCreditIssue({ item })}
                  </Stack>
                </Card>
              )
            })}
          </div>
          <Dialog
            open={showEmailModal}
            onClose={() => setShowEmailModal(false)}
            title="Email Preview"
            width="content"
            inset="none"
          >
            <Box maxWidth={['100%', '100%', '420px']}>
              <Divider />
              <Box inset="m">
                <LeadEmailPreview lead={lead} />
              </Box>
              <Divider />
              <Box inset="m" display="flex" flexDirection="row-reverse">
                <PrimaryButton onClick={() => setShowEmailModal(false)}>Close</PrimaryButton>
              </Box>
            </Box>
          </Dialog>
        </>
      )}
    </>
  )
}

export default LeadTimeline
