import { useState } from 'react'
import { Mic, MicOff, Phone, Timer, TimerOff, X } from 'lucide-react'
import { parsePhoneNumberFromString, isValidNumber } from 'libphonenumber-js'
import { Dialog, Divider } from '@leadrilla/pulsar'
import { useAuth } from '../../../components/AuthProvider/auth_provider'
import { getVoiceTwilioNumber } from '../../../helpers/voip'
import backend from '../../../backend'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../../components/ui/tooltip'
import { useVoip } from '../VoipProvider'
import { cn } from '../../../helpers/utils'
import { Keypad } from '../../../components/ui/keypad'

export interface VoipParticipant {
  callSid: string
  conferenceSid: string
  label: string
  hold: boolean
  muted: boolean
  status: 'queued' | 'connecting' | 'ringing' | 'connected' | 'complete' | 'failed'
}

interface ParticipantUpdate {
  muted?: boolean
  hold?: boolean
}

const updateParticipant = ({
  participant,
  update,
}: {
  participant: VoipParticipant
  update: ParticipantUpdate
}) => {
  backend.post('/voip/update-participant', {
    conferenceSid: participant.conferenceSid,
    callSid: participant.callSid,
    update,
  })
}

const removeParticipant = ({ participant }: { participant: VoipParticipant }) => {
  backend.post('/voip/remove-participant', {
    conferenceSid: participant.conferenceSid,
    callSid: participant.callSid,
  })
}

const VoipParticipants = ({ participants }: { participants: VoipParticipant[] }) => {
  const { user } = useAuth()
  const { voipState } = useVoip()

  const [devModeParticipant, setDevModeParticipant] = useState<VoipParticipant>({
    callSid: '123',
    conferenceSid: '123',
    label: 'Leady McLeaderson - (859) 867-5309',
    hold: false,
    muted: false,
    status: 'connected',
  })

  if (voipState.devMode) participants = [devModeParticipant]

  const voiceNumber = getVoiceTwilioNumber({ user })
  let agentNumber = voiceNumber
  try {
    agentNumber = parsePhoneNumberFromString(voiceNumber)?.formatNational()
  } catch {
    // do nothing
  }

  const toggleMuted = (participant: VoipParticipant) => {
    if (participant.hold) return
    if (voipState.devMode) {
      setDevModeParticipant({
        ...devModeParticipant,
        muted: !participant.muted,
      })
    } else {
      updateParticipant({ participant, update: { muted: !participant.muted } })
    }
  }

  const toggleHold = (participant: VoipParticipant) => {
    if (voipState.devMode) {
      setDevModeParticipant({
        ...devModeParticipant,
        hold: !participant.hold,
        muted: false,
      })
    } else {
      updateParticipant({ participant, update: { hold: !participant.hold, muted: false } })
    }
  }

  const [newParticipantNumber, setNewParticipantNumber] = useState('')
  const onKeyPress = (key: string) => {
    if (key === 'Backspace') {
      setNewParticipantNumber(newParticipantNumber.slice(0, -1))
    } else {
      setNewParticipantNumber(newParticipantNumber + key)
    }
  }

  let formattedNewParticipantNumber = newParticipantNumber
  if (newParticipantNumber) {
    const formatted = parsePhoneNumberFromString(newParticipantNumber, 'US')?.formatNational()
    if (formatted) formattedNewParticipantNumber = formatted
  }

  const [addParticipantModalOpen, setAddParticipantModalOpen] = useState(false)
  const closeAddParticipantModal = () => {
    setAddParticipantModalOpen(false)
    setNewParticipantNumber('')
  }

  const conferenceSid = participants[0]?.conferenceSid

  const addParticipant = () => {
    if (isValidNumber(newParticipantNumber, 'US')) {
      backend.post('/voip/add-participant', {
        conferenceSid,
        number: '+1' + newParticipantNumber,
      })
      closeAddParticipantModal()
    }
  }

  return (
    <div>
      <div className="flex h-[56px] items-center border-b border-solid border-tenant-brand-hairline">
        <p>Me - {agentNumber}</p>
      </div>
      {participants.map((participant) => (
        <div className="flex h-[56px] items-center justify-between border-b border-solid border-tenant-brand-hairline">
          <div className="flex gap-4">
            <p>{participant.label}</p>
            {participant.hold && <p className="font-semibold text-destructive">ON HOLD</p>}
            {participant.muted && !participant.hold && (
              <p className="font-semibold text-destructive">MUTED</p>
            )}
          </div>
          <div className="flex gap-4">
            <Tooltip open={participant.hold ? false : undefined}>
              <TooltipTrigger disabled={participant.hold}>
                <div
                  className={cn(
                    { 'bg-gray-100': participant.hold },
                    'flex h-[32px] w-[32px] items-center justify-center rounded-full shadow-[0_0_4px_rgba(0,0,0,0.1)]'
                  )}
                  onClick={() => toggleMuted(participant)}
                >
                  {participant.muted ? (
                    <MicOff size={16} color="black" />
                  ) : (
                    <Mic size={16} color="black" />
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent>{participant.muted ? 'Unmute' : 'Mute'}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger>
                <button
                  className="flex h-[32px] w-[32px] items-center justify-center rounded-full shadow-[0_0_4px_rgba(0,0,0,0.1)]"
                  onClick={() => toggleHold(participant)}
                >
                  {participant.hold ? (
                    <TimerOff size={16} color="black" />
                  ) : (
                    <Timer size={16} color="black" />
                  )}
                </button>
              </TooltipTrigger>
              <TooltipContent>{participant.hold ? 'Take off hold' : 'Put on hold'}</TooltipContent>
            </Tooltip>
            {participants.length > 1 && (
              <Tooltip>
                <TooltipTrigger>
                  <button
                    className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full shadow-[0_0_4px_rgba(0,0,0,0.1)]"
                    onClick={() => removeParticipant({ participant })}
                  >
                    <Phone color="black" size={16} style={{ transform: 'rotate(135deg)' }} />
                  </button>
                </TooltipTrigger>
                <TooltipContent>Remove participant</TooltipContent>
              </Tooltip>
            )}
          </div>
        </div>
      ))}
      <button
        onClick={() => setAddParticipantModalOpen(true)}
        className="pt-4 font-bold text-tenant-brand-action"
      >
        + Add participant
      </button>

      <Dialog open={addParticipantModalOpen} onClose={closeAddParticipantModal} radius="m">
        <div className="flex justify-between p-6">
          <h3>Add participant</h3>
          <button onClick={closeAddParticipantModal}>
            <X />
          </button>
        </div>
        <Divider />
        <div className="flex w-[400px] flex-col items-center justify-center pb-14">
          {formattedNewParticipantNumber ? (
            <p className="py-9 text-[18px] font-semibold">{formattedNewParticipantNumber}</p>
          ) : (
            <p className="py-9 text-[18px] font-semibold text-gray-400">Enter number to dial</p>
          )}
          <Keypad onKeyPress={onKeyPress} enableBackspace />
          <button
            className="mt-6 flex h-[56px] w-[56px] select-none items-center justify-center rounded-full bg-tenant-brand-positive shadow-[0_0_8px_rgba(0,0,0,0.1)]"
            onClick={addParticipant}
          >
            <Phone color="white" />
          </button>
        </div>
      </Dialog>
    </div>
  )
}

export default VoipParticipants
