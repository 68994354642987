import { useState } from 'react'
import { Headset } from 'lucide-react'
import { Dialog, Dropdown, FieldLabel, Heading, Icon, Text } from '@leadrilla/pulsar'
import { useVoip, VoipActionType } from '../../AgentPortal/Voip/VoipProvider'

const AudioDeviceManager = () => {
  const { voipState, voipDispatch } = useVoip()

  const testOutputDevice = () => {
    const audio = new Audio('https://sdk.twilio.com/js/client/sounds/releases/1.0.0/outgoing.mp3')

    // Clean up audio element after playing
    audio.addEventListener('ended', () => {
      audio.remove()
    })

    audio.addEventListener('error', (err) => {
      console.error('Error testing output device:', err)
    })

    try {
      ;(audio as any).setSinkId(voipState.selectedOutputDeviceId).then(() => audio.play())
    } catch (err) {
      // Browser doesn't support HTMLMediaElement.setSinkId()
      // Play using the default audio device
      audio.play()
    }
  }

  const [audioDeviceManagerModalOpen, setAudioDeviceManagerModalOpen] = useState(false)

  const deviceOptions = (devices: MediaDeviceInfo[]) =>
    devices.map((device) => ({
      text: device.label.replace(/ *\([^)]*\) */g, ''),
      value: device.deviceId,
    }))

  return (
    <>
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={() => setAudioDeviceManagerModalOpen(!audioDeviceManagerModalOpen)}
      >
        {voipState.devMode ? (
          <div className="flex flex-col items-center font-bold text-[--colors-negative]">
            <div>VoIP</div>
            <div>DEV MODE</div>
          </div>
        ) : (
          <>
            {/* TODO - update this to display the error state when there is no input device selected or when there is no output device selected */}
            {voipState.voipShouldBeConnected &&
              (voipState.voipIsConnected ? (
                <Icon name="success" tone="positive" size="s" />
              ) : (
                <Icon name="critical" tone="negative" size="s" />
              ))}
            <Headset
              className={`
            ${!voipState.voipAllowed && 'hidden'} 
            ${
              voipState.voipShouldBeConnected &&
              !voipState.voipIsConnected &&
              'text-[--colors-negative]'
            }
          `}
            />
          </>
        )}
      </div>

      <Dialog
        open={audioDeviceManagerModalOpen}
        onClose={() => setAudioDeviceManagerModalOpen(false)}
        overflow="visible"
      >
        <div className="flex w-[348px] flex-col gap-[24px] p-[16px]">
          <div className="flex justify-between">
            <Heading level={3}>Audio Settings</Heading>
            <div className="cursor-pointer" onClick={() => setAudioDeviceManagerModalOpen(false)}>
              <Icon name="close" tone="light" size="m" />
            </div>
          </div>

          {voipState.voipShouldBeConnected && (
            <div className="flex items-center">
              <div className="flex items-center gap-2">
                {voipState.voipIsConnected ? (
                  <Icon name="success" tone="positive" size="m" />
                ) : (
                  <Icon name="critical" tone="negative" size="m" />
                )}
                {voipState.voipIsConnected ? (
                  <Text>Ready to make calls</Text>
                ) : (
                  <>
                    <div className="flex flex-col gap-1">
                      {voipState.microphonePermissionGranted ? (
                        <>
                          <Text>Cannot receive calls</Text>
                          <Text>Please check your internet connection</Text>
                        </>
                      ) : (
                        <Text>Microphone permissions have been denied</Text>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {voipState.microphonePermissionGranted && (
            <>
              <Dropdown
                label="Microphone"
                options={deviceOptions(voipState.availableInputDevices)}
                value={voipState.selectedInputDeviceId}
                onChange={(deviceId) =>
                  voipDispatch({
                    type: VoipActionType.SET_SELECTED_INPUT_DEVICE_ID,
                    payload: { selectedInputDeviceId: deviceId },
                  })
                }
              />

              <div className="flex flex-col gap-[8px]">
                <div className="flex justify-between">
                  <FieldLabel label="Speakers" />
                  <div
                    className="bold cursor-pointer text-[--colors-action]"
                    onClick={testOutputDevice}
                  >
                    Test
                  </div>
                </div>
                <Dropdown
                  options={deviceOptions(voipState.availableOutputDevices)}
                  value={voipState.selectedOutputDeviceId}
                  onChange={(deviceId) =>
                    voipDispatch({
                      type: VoipActionType.SET_SELECTED_OUTPUT_DEVICE_ID,
                      payload: { selectedOutputDeviceId: deviceId },
                    })
                  }
                  disabled={voipState.availableOutputDevices.length < 2}
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default AudioDeviceManager
