import { Dispatch } from 'react'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { VoipActionType, VoipAction } from './VoipReducer'

// TODO - move dev mode things to a separate file
// Fake Twilio inbound call for dev mode
export class DevModeInboundCall {
  state: TwilioCall.State
  muted: boolean = false
  parameters: Record<string, string>
  dispatch: Dispatch<VoipAction>

  constructor(_dispatch: Dispatch<VoipAction>) {
    this.state = TwilioCall.State.Pending
    this.parameters = {
      From: '+18598675309',
    }
    this.dispatch = _dispatch
  }

  status() {
    return this.state
  }

  accept() {
    this.state = TwilioCall.State.Open
    this.dispatch({
      type: VoipActionType.SET_INBOUND_CALL,
      payload: {
        inboundCall: this as unknown as TwilioCall,
      },
    })
    this.dispatch({
      type: VoipActionType.SET_INBOUND_CALL_ACCEPTED,
      payload: { inboundCallAccepted: true },
    })
  }

  reject() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SET_INBOUND_CALL,
      payload: { inboundCall: this as unknown as TwilioCall },
    })
    this.dispatch({
      type: VoipActionType.SET_INBOUND_CALL_ACCEPTED,
      payload: { inboundCallAccepted: false },
    })
  }

  disconnect() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.CLEAR_INBOUND_CALL,
    })
  }

  mute(shouldMute?: boolean) {
    this.muted = shouldMute ?? true
    this.dispatch({
      type: VoipActionType.SET_INBOUND_CALL,
      payload: {
        inboundCall: this as unknown as TwilioCall,
      },
    })
  }

  isMuted() {
    return this.muted
  }

  sendDigits(key: string) {
    console.log(`sent key ${key}`)
  }
}
