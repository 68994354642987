import { Dialog, Divider, Heading, PrimaryButton, SecondaryButton, Text } from '@leadrilla/pulsar'
import { useVoip } from './VoipProvider'
import { useEffect, useState } from 'react'
import { useTenantConfig } from '../../hooks/TenantConfig'
import { useCampaigns } from '../../hooks/campaigns'
import { Device } from '@twilio/voice-sdk'
import { useIdleTimer as useReactIdleTimer } from 'react-idle-timer'
import { useEnabledCallCampaign } from 'src/hooks/queries/useEnabledCallCampaign'

const IdleTimer = () => {
  const tenantConfig = useTenantConfig()
  const { pauseCampaign } = useCampaigns()
  const { voipState } = useVoip()
  const [promptIsOpen, setPromptIsOpen] = useState(false)
  const [remainingTime, setRemainingTime] = useState<number>()
  const { data: enabledCallCampaign } = useEnabledCallCampaign()

  const disabled =
    voipState.device?.state !== Device.State.Registered ||
    !!voipState.inboundCall ||
    !enabledCallCampaign

  const pause = () => {
    setPromptIsOpen(false)
    if (enabledCallCampaign) {
      pauseCampaign({ id: enabledCallCampaign.id })
    }
  }

  const { getRemainingTime, reset: resetTimer } = useReactIdleTimer({
    disabled,
    timeout: voipState.devMode ? 75 * 1000 : tenantConfig.campaigns.ipp_idle_timeout,
    promptBeforeIdle: 60 * 1000,
    onPrompt: () => setPromptIsOpen(true),
    onIdle: pause,
  })

  const reset = () => {
    setPromptIsOpen(false)
    resetTimer()
  }

  // Create an interval that updates remainingTime and the document title every 500 ms
  useEffect(() => {
    if (!disabled) {
      const interval = setInterval(() => {
        const remaining = Math.ceil(getRemainingTime() / 1000)
        setRemainingTime(remaining)
        if (remaining < 180) {
          const minutes = Math.floor(remaining / 60)
          const seconds = remaining % 60
          document.title = `Campaign pausing in ${minutes ? minutes + 'm ' : ''}${seconds
            .toString()
            .padStart(2, '0')}s`
        } else if (document.title !== tenantConfig.title) {
          document.title = tenantConfig.title
        }
      }, 500)
      return () => {
        document.title = tenantConfig.title
        clearInterval(interval)
      }
    }
  }, [disabled])

  return (
    <Dialog open={promptIsOpen} onClose={() => setPromptIsOpen(false)}>
      <div className="w-[375px]">
        <div className="p-[16px]">
          <Heading level={3}>Are you still there?</Heading>
        </div>
        <Divider />
        <div className="flex flex-col gap-[24px] p-[16px]">
          <Text component="p">
            It looks like you’ve been idle for a few minutes. Are you still there? Your campaign
            will be automatically paused in {remainingTime} seconds.
          </Text>
          <div className="flex justify-between">
            <SecondaryButton onClick={pause}>Pause my campaign</SecondaryButton>
            <PrimaryButton onClick={reset}>Yes, I'm still here</PrimaryButton>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default IdleTimer
