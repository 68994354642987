import backend from '../../backend'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from 'src/components/AuthProvider/auth_provider'
import { LeadFeed } from 'src/types/lead_feed'

const fetchEnabledCallCampaign = async (): Promise<LeadFeed | null> => {
  const url = `/lead-feeds/enabled?product_type=calls`
  const { body: enabledCallCampaigns } = await backend.get(url)
  if (enabledCallCampaigns.length > 0) {
    // There should only be one enabled call campaign, so return the first one
    return enabledCallCampaigns[0]
  }
  return null
}

/**
 * Returns the currently enabled call campaign for the current user
 */
export const useEnabledCallCampaign = () => {
  const { user } = useAuth()
  return useQuery<LeadFeed | null>({
    queryKey: ['campaigns', 'calls', 'enabled', user?.id],
    queryFn: () => fetchEnabledCallCampaign(),
  })
}
