import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
import VoipWindow from './VoipWindow/VoipWindow'
import { useVoip } from './VoipProvider'
import { usePusherEvent } from '../../hooks/pusher'
import { Call, Device } from '@twilio/voice-sdk'
import { useQueryClient } from '@tanstack/react-query'
import { useEnabledCallCampaign } from 'src/hooks/queries/useEnabledCallCampaign'

const VoipBar = () => {
  const { voipState } = useVoip()
  const { data: enabledCallCampaign } = useEnabledCallCampaign()
  const queryClient = useQueryClient()

  // Unregister voip if the currently active call campaign was paused
  usePusherEvent({
    channel: 'private-voip',
    event: 'campaign-paused',
    onEvent: () => {
      queryClient.invalidateQueries({ queryKey: ['campaigns'] })
    },
  })

  const callActive =
    voipState.inboundCall?.status() === Call.State.Open ||
    voipState.outboundCall?.status() === Call.State.Open ||
    voipState.outboundCall?.status() === Call.State.Connecting

  const deviceIsRegistered =
    voipState.device?.state === Device.State.Registered || voipState.devMode
  const showVoipBar =
    deviceIsRegistered &&
    (Boolean(voipState.inboundCall) || Boolean(voipState.userLead) || !!enabledCallCampaign)

  return (
    <>
      {showVoipBar && (
        // @ts-expect-error FIXME
        <AnimatePresence>
          <motion.div
            initial={{ y: 100, opacity: 0.9 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <div className="fixed bottom-0 z-20 flex h-[100px] w-full flex-col-reverse items-center">
              <div
                className={cn('fixed bottom-0 z-20 h-[18px] w-full', {
                  'bg-[--colors-action]': !callActive,
                  'bg-[--colors-positive]': callActive,
                })}
              />
              <VoipWindow />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  )
}

export default VoipBar
